import React from 'react';

const IconLoader = () => (
  <svg
    id="logo"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 300 300"
    shapeRendering="geometricPrecision"
    textRendering="geometricPrecision"
  >
    <rect
      width="170"
      height="170"
      rx="0"
      ry="0"
      transform="matrix(.707107-.707107 0.707107 0.707107 52.262559 149.999997)"
      fill="none"
      stroke="#FFD073"
      strokeWidth="5"
    />
    <text
      id="B"
      dx="0"
      dy="0"
      fontFamily="Arial"
      fontSize="20"
      fontWeight="500"
      transform="matrix(6.138132 0 0 6.138132 97.247089 183.432018)"
      fill="#FFD073"
      strokeWidth="0"
    >
      <tspan y="0" fontWeight="500" strokeWidth="0">
        JJ
      </tspan>
    </text>
  </svg>
);

export default IconLoader;
